<template src="./templates/calculate.html"></template>

<script>

import {
    calculate
} from '@api/scenarios';

import Filter from '@/entities/filter';

import Breadcrumbs from '@c/Breadcrumbs';
import PageTitle from '@c/PageTitle';
import PageHeader from '@c/Header';
import FormInput from '@f/Input';
import FormButton from '@f/Button';

export default {
    name: 'Calculate',
    components: {
        Breadcrumbs,
        PageHeader,
        PageTitle,
        FormInput,
        FormButton,
    },
    data() {
        return {
            query: {
                partnerId: 73892, // 73892 - отдел рекламы
                partnerCode: null,
                questionnaireId: 28, // Прикатывающие катки
                questionnaireCode: null,
            },
            get globalFilter() {
                return new Filter();
            },
            result: null,
        };
    },
    created() {
        this.initFilter();
    },
    methods: {
        initFilter() {
            const filter = this.globalFilter.getGroup('partner');
            if (filter.id) {
                this.query.partnerId = filter.id;
            }
        },
        calculatePoints() {
            this.result = null;
            calculate({...this.query}, response => {
                console.log(response);
                this.result = response;
            });
        },
    },
};
</script>
