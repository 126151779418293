<div>
	<page-header>
		<div class="row c">
			<breadcrumbs :items="[{title:'Калькуляция'}]" />
		</div>
	</page-header>

	<page-title title="Калькуляция баллов" :params="{/*subtitle:'Список переменных'*/}" />
    
    <div class="container">
        <div class="row c mt-4 mb-3 flex-direction-column">
            <div class="row-col">
                <div class="col">
                   Опрос:
                </div>
            </div>
        </div>
        <div class="row c mt-4 mb-3 flex-direction-column">
            <div class="row-col">
                <div class="col-6">
                   ID: <form-input  v-model="query.questionnaireId" />
                </div>
<!--                <div class="col-6">
                   Код: <form-input></form-input>
                </div>-->
            </div>
        </div>
        <div class="row c mt-4 mb-3 flex-direction-column">
            <div class="row-col">
                <div class="col">
                   Контрагент:
                </div>
            </div>
        </div>
        <div class="row c mt-4 mb-3 flex-direction-column">
            <div class="row-col">
                <div class="col-6">
                   ID: <form-input v-model="query.partnerId" />
                </div>
<!--                <div class="col-6">
                   Код: <form-input></form-input>
                </div>-->
            </div>
<!--            <div class="row-col">
                <div class="col-6">
                   Контакт: <form-input></form-input>
                </div>
            </div>-->
        </div>
        <div class="row c mt-4 mb-3 flex-direction-column">
            <div class="row-col">
                <div class="col">
                    <form-button @click="calculatePoints" label="Просчитать" />
                </div>
            </div>
        </div>
        
        <div v-if="result && result.answers" class="row c mt-4 mb-3 flex-direction-column">
            <div class="row-col">
                <div class="col">
                    <pre>{{result.answers}}</pre>
                </div>
            </div>
        </div>
    </div>
</div>